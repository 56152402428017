import {Elements} from "@stripe/react-stripe-js";
import React, {useEffect, useMemo, useState} from "react";
import {loadStripe, Stripe, StripeElementLocale} from "@stripe/stripe-js";
import {InstrumentHostedSession} from "src/domain/HostedSession";
import {Assets, Branding, GooglePaySettings, PresentationSettings} from "src/domain/PresentationSettings";
import {validate, ValidationError} from "class-validator";
import {
    InitRequest,
    InitRequestType,
    InitResponse,
    Nullable
} from "src/workers/types";
import config from "src/config";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { dispatch } from "src/workers/common-worker";
import { useTheme } from "src/theme/ThemeContext";
import { GetLoading } from "src/theme/Loading";
import { Grid } from "@mui/material";
import SetupPageHeader from "../components/SetupPageHeader";
import SetupFormStripe from "../components/SetupFormStripe"
import { t } from "i18next";
import SetupFormPayrails from "../components/SetupFormPayrails";
import logger from "src/logger";

const PayrailsProvider: string = "Payrails";
const StripeProvider: string = "Stripe";

export default function Setup() {
    const { t, i18n } = useTranslation();
    const { theme, isThemeLoaded } = useTheme();
    const navigate = useNavigate();
    const { param } = useParams();
    const [clientSecret, setClientSecret] = useState("");
    const [stripePromise, setStripePromise] = useState<Promise<Stripe | null>|null>(null);
    const [validationErrors, setValidationErrors] = useState<ValidationError[]>([]);
    const [instrumentHostedSession, setInstrumentHostedSession] = useState<InstrumentHostedSession>();
    const [checkoutCompleted, setCheckoutCompleted] = useState(false);
    const [isStripeProvider, setIsStripeProvider] = useState(false);
    const [isPayrailsProvider, setIsPayrailsProvider] = useState(false);
    const location = useLocation();
    const cId = location.hash.substring(1);
    const cIdExists = cId.length > 0;
    const paymentsServiceUrl: string =  config.apis.paymentServiceUrl;
    const tokenMediatorUrl: string =  config.apis.tokenMediatorUrl;
    const [provider, setProvider] = useState<string>();
    const worker: Worker = useMemo(
        () => new Worker(new URL("../workers/init-setup-worker.ts", import.meta.url)),
        []
    );

    const mapLocale = (locale: StripeElementLocale) : string => {
        if (locale === "zh") {
            return "zh_Hans";
        }
        if (locale === "zh-HK") {
            return "zh_Hant_HK";
        }
        if (locale === "nb") {
            return "nb_NO";
        }

        return (locale as string).replace("-", "_");
    }

    let _logger = logger.child({
        hostedSessionID: param
    })

    useEffect(() => {
        if(!isThemeLoaded){
            return;
        }

        if (Worker) {
            const hsID: string = param || "";
            const request: InitRequest = {
                tokenMediatorUrl: tokenMediatorUrl,
                paymentsServiceUrl: paymentsServiceUrl,
                hsID: hsID,
                configId: cIdExists ? cId : "",
                type: InitRequestType.Setup
            };

            dispatch<InitRequest, InitResponse>(worker, request)
                .then((data: Nullable<InitResponse>) => buildSetupPage(data))
                .then((errors) => setValidationErrors(errors));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [param, isThemeLoaded]);

    if(!isThemeLoaded){
        return null;
    }

    if(checkoutCompleted){
        return (
            <div className="centerExpired">
                <h2>{t('expiredLink')}</h2>
                <p>{t('sessionExpired')}</p>
                <p>
                    <div className="clickable-text" style={{fontSize: '16px', fontWeight: 600}} onClick={() => navigate(-1)}>
                        {t('back')}
                    </div>
                </p>
            </div>
        )
    }

    if (!instrumentHostedSession) {
        return (<div>{(<GetLoading src={theme.assets.loadingSpinner}></GetLoading>)}</div>);
    }

    async function buildSetupPage(data) : Promise<ValidationError[]> {
        setCheckoutCompleted(data.is_completed_or_expired);

        if (data.is_completed_or_expired){
            return new Array<ValidationError>();
        }

        let hostedSession = new InstrumentHostedSession();
        hostedSession.client_secret = data.client_secret;
        hostedSession.success_url = data.success_url;
        if (data.locale === "auto") {
            hostedSession.locale = "en";
        } else {
            hostedSession.locale = data.locale;
        }

        let presentationSettings = new PresentationSettings();

        let assets = new Assets();
        assets.logo = theme.assets.logo;
        assets.favicon = theme.assets.favicon;

        let branding = new Branding();
        branding.button_color = theme.branding.buttonColor;
        branding.background_color = theme.branding.backgroundColor;

        presentationSettings.assets = assets;
        presentationSettings.branding = branding;

        presentationSettings.display_name = data.presentation_settings.display_name;
        presentationSettings.terms_of_service_url = data.presentation_settings.terms_of_service_url;
        presentationSettings.privacy_policy_url = data.presentation_settings.privacy_policy_url;
        presentationSettings.google_pay = new GooglePaySettings(
            data.presentation_settings.google_pay.merchant_name,
            data.presentation_settings.google_pay.merchant_id,
        )

        hostedSession.presentation_settings = presentationSettings;

        if (data.test_mode) {
            hostedSession.test_mode = data.test_mode
        }

        hostedSession.tenant_id = data.t_id

        const errors: ValidationError[] = await validate(hostedSession, { skipMissingProperties: true });
        if (errors.length > 0) {
            return errors;
        }

        setInstrumentHostedSession(hs =>({
            ...hostedSession,
        }));
        setClientSecret(hostedSession.client_secret);
        
        await i18n.changeLanguage(mapLocale(hostedSession.locale));
        document.title = `${t('pageTitle')}`

        if (data.gateway === PayrailsProvider){
            setIsPayrailsProvider(true);
            setProvider(PayrailsProvider);
            _logger.info("setup page initialized")
            return errors;
        }

        const providerAccount = data.provider_account ?? data.gateway_account;
        const pKey = hostedSession.test_mode ? config.gateway.testApiKeys[providerAccount] : config.gateway.apiKeys[providerAccount];
        setStripePromise(loadStripe(pKey));
        setIsStripeProvider(true);
        setProvider(StripeProvider);
        _logger.info("setup page initialized")

        return errors;
    }

    const appearance = {
        variables: {
            fontFamily: 'Ideal Sans, system-ui, sans-serif',
            spacingUnit: '4px',
            borderRadius: '4px',
            fontSize: '16px',
        }
    };
    const options = {
        clientSecret,
        appearance,
        layout: {
            type: 'tabs',
            defaultCollapsed: false,
        },
        wallets: {
            applePay: 'auto',
            googlePay: 'auto',
        }
    };

    const stripeFormData = {
        options: options,
        stripePromise: stripePromise,
        hostedSession: instrumentHostedSession
    }

    return (
        <div style={{margin: "12px"}}>
            <div className="top-margin">
                <Grid container spacing={1} direction="row" justifyContent="center">
                    <Grid item xs={12} sm={6} md={4}>
                        <SetupPageHeader instrumentHostedSession={instrumentHostedSession}></SetupPageHeader>
                        
                        {isStripeProvider && clientSecret && validationErrors.length === 0 && (
                            <SetupFormStripe stripeFormData={stripeFormData}></SetupFormStripe>
                        )}

                        {isPayrailsProvider && clientSecret && validationErrors.length === 0 && (
                            <SetupFormPayrails instrumentHostedSession={instrumentHostedSession}></SetupFormPayrails>
                        )}
                        
                    </Grid>
                </Grid>

                <SmallFooter provider={provider}></SmallFooter>
            </div>
        </div>
    );
}

const footerLinks = {
    Stripe: {
        terms: "https://stripe.com/legal/end-users",
        privacy: "https://stripe.com/privacy"
    },
    Payrails:{
        terms: "https://www.payrails.com/imprint",
        privacy: "https://www.payrails.com/privacy-policy"
    }
}

function SmallFooter({provider}){
    if (!provider) {
        return null
    }
    
    return (
        <div style={{textAlign: "center", bottom: 0, marginTop: "20px"}}>
            <a className="dottedLink" href={footerLinks[provider].terms}>{t("terms")}</a>
            <a className="dottedLink" href={footerLinks[provider].privacy}>{t("privacy")}</a>
        </div>
    )
}