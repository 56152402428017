import React, {useState, useEffect, useMemo, Suspense } from "react";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { loadStripe, Stripe, StripeElementLocale } from "@stripe/stripe-js";
import {HostedSession, Instrument} from "src/domain/HostedSession";
import {validate, ValidationError} from "class-validator";
import config from "src/config";
import {
    InitRequest,
    InitRequestType,
    InitResponse,
    Nullable
} from "src/workers/types";
import { dispatch } from "src/workers/common-worker";
import { useTranslation } from 'react-i18next';
import {Assets, Branding, GooglePaySettings, PresentationSettings} from "src/domain/PresentationSettings";
import { GetLoading } from "src/theme/Loading";
import { useTheme } from "src/theme/ThemeContext";
import { Grid } from "@mui/material";
import CheckoutFormPayrails from "../components/CheckoutFormPayrails";
import CheckoutFormStripe from "../components/CheckoutFormStripe";
import CheckoutSummary from "../components/CheckoutSummary";
import { t } from "i18next";
import CheckoutError from "../components/CheckoutError";
import logger from "src/logger";

const PayrailsProvider: string = "Payrails";
const StripeProvider: string = "Stripe";

export default function Checkout() {
    const { t, i18n } = useTranslation();
    const { theme, isThemeLoaded } = useTheme();
    const [clientSecret, setClientSecret] = useState("");
    const [stripePromise, setStripePromise] = useState<Promise<Stripe | null>|null>(null);
    const { param } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const cId = location.hash.substring(1);
    const cIdExists = cId.length > 0;

    const [hostedSession, setHostedSession] = useState<HostedSession>();
    const [validationErrors, setValidationErrors] = useState<ValidationError[]>([]);
    const [checkoutCompleted, setCheckoutCompleted] = useState(false);
    const [isStripeProvider, setIsStripeProvider] = useState(false);
    const [isPayrailsProvider, setIsPayrailsProvider] = useState(false);
    const [isCheckoutLoadError, setIsCheckoutLoadError] = useState(false);
    const worker: Worker = useMemo(
        () => new Worker(new URL("../workers/init-worker.ts", import.meta.url)),
        []
    );

    const paymentsServiceUrl: string =  config.apis.paymentServiceUrl
    const tokenMediatorUrl: string =  config.apis.tokenMediatorUrl

    const [provider, setProvider] = useState<string>();

    let _logger = logger.child({
        hostedSessionID: param
    })

    const mapLocale = (locale: StripeElementLocale) : string => {
        if (locale === "zh") {
            return "zh_Hans";
        }
        if (locale === "zh-HK") {
            return "zh_Hant_HK";
        }
        if (locale === "nb") {
            return "nb_NO";
        }

        return (locale as string).replace("-", "_");
    }

    useEffect(() => {

        if(!isThemeLoaded){
            return;
        }

        if (Worker) {
            const hsID: string = param || "";
            const request: InitRequest = {
                tokenMediatorUrl: tokenMediatorUrl,
                paymentsServiceUrl: paymentsServiceUrl,
                hsID: hsID,
                configId: cIdExists ? cId : "",
                type: InitRequestType.Payment
            };

            dispatch<InitRequest, InitResponse>(worker, request)
                .then((data: Nullable<InitResponse>) => buildPaymentPage(data))
                .then((errors) => setValidationErrors(errors))
                .catch((error) => {
                    _logger.error({
                        error: error instanceof Error ? error.message : JSON.stringify(error)
                    }, "an error occurred while initializing the payment page");
                    setIsCheckoutLoadError(true)
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isThemeLoaded]);

    if(!isThemeLoaded){
        return null;
    }

    if(checkoutCompleted){
        return (
            <div className="centerExpired">
                <h2>{t('expiredLink')}</h2>
                <p>{t('sessionExpired')}</p>
                <p>
                    <div className="clickable-text" style={{fontSize: '16px', fontWeight: 600}} onClick={() => navigate(-1)}>
                        {t('back')}
                    </div>
                </p>
            </div>
        )
    }

    if(isCheckoutLoadError){
        return (<CheckoutError themeSettings={theme}></CheckoutError>);
    }

    if (!hostedSession) {
        return (<div>{(<GetLoading src={theme.assets.loadingSpinner}></GetLoading>)}</div>);
    }

    async function buildPaymentPage(data) : Promise<ValidationError[]> {
        setCheckoutCompleted(data.is_completed_or_expired);

        if (data.is_completed_or_expired){
            return new Array<ValidationError>();
        }

        let hostedSession = new HostedSession();
        hostedSession.amount = data.amount;

        data.items.forEach(item => {
            hostedSession.items.push(item);
        });

        hostedSession.client_secret = data.client_secret;
        hostedSession.currency = data.currency;
        hostedSession.success_url = data.success_url;
        hostedSession.cancel_url = data.cancel_url;
        if (data.locale === "auto") {
            hostedSession.locale = "en";
        } else {
            hostedSession.locale = data.locale;
        }

        if (data.instrument) {
            let instrument = new Instrument();
            instrument.id = data.instrument.id;
            instrument.brand = data.instrument.brand;
            instrument.last4 = data.instrument.last4;

            hostedSession.instrument = instrument;
        }

        let presentationSettings = new PresentationSettings();

        let assets = new Assets();
        assets.logo = theme.assets.logo;
        assets.favicon = theme.assets.favicon;

        let branding = new Branding();
        branding.button_color = theme.branding.buttonColor;
        branding.background_color = theme.branding.backgroundColor;

        presentationSettings.assets = assets;
        presentationSettings.branding = branding;

        presentationSettings.display_name = data.presentation_settings.display_name;
        presentationSettings.terms_of_service_url = data.presentation_settings.terms_of_service_url;
        presentationSettings.privacy_policy_url = data.presentation_settings.privacy_policy_url;
        presentationSettings.google_pay = new GooglePaySettings(
            data.presentation_settings.google_pay.merchant_name,
            data.presentation_settings.google_pay.merchant_id,
        )

        hostedSession.presentation_settings = presentationSettings;

        if (data.test_mode) {
            hostedSession.test_mode = data.test_mode
        }

        hostedSession.tenant_id = data.t_id

        const errors: ValidationError[] = await validate(hostedSession, { skipMissingProperties: true });

        if (errors.length > 0) {
            return errors;
        }

        setHostedSession(hs =>({
            ...hostedSession,
        }));

        setClientSecret(hostedSession.client_secret);

        await i18n.changeLanguage(mapLocale(hostedSession.locale));
        document.title = `${t('pageTitle')}`

        const provider = data.provider ?? data.gateway;
        const providerAccount = data.provider_account ?? data.gateway_account;
        if (provider === PayrailsProvider){
            setIsPayrailsProvider(true);
            setProvider(PayrailsProvider);
            _logger.child({
                provider: PayrailsProvider
            }).info("payment page initialized")
            return errors;
        }

        const pKey = hostedSession.test_mode ? config.gateway.testApiKeys[providerAccount] : config.gateway.apiKeys[providerAccount];
        setStripePromise(loadStripe(pKey, {locale: hostedSession.locale}));
        setIsStripeProvider(true);
        setProvider(StripeProvider);
        _logger.child({
            provider: StripeProvider
        }).info("payment page initialized")

        return errors;
    }

    const appearance = {
        variables: {
            fontFamily: 'Ideal Sans, system-ui, sans-serif',
            spacingUnit: '4px',
            borderRadius: '4px',
            fontSize: '16px',
        }
    };

    const options = {
        clientSecret,
        appearance,
        layout: {
            type: 'tabs',
            defaultCollapsed: false,
        },
        wallets: {
            applePay: 'auto',
            googlePay: 'auto',
        }
    };

    const stripeFormData = {
        options: options,
        stripePromise: stripePromise,
        hostedSession: hostedSession
    }

    return (
        <div className="top-margin">
            <Grid container spacing={1} direction="row" justifyContent="center">
                <Grid item xs={12} sm={6} md={4} style={{position: "relative"}}>
                    <CheckoutSummary hostedSession={hostedSession}></CheckoutSummary>
                    <CheckoutFooter provider={provider}></CheckoutFooter>
                </Grid>
                {isStripeProvider && clientSecret && validationErrors.length === 0 && (
                    <CheckoutFormStripe stripeFormData={stripeFormData}></CheckoutFormStripe>
                )}
                {isPayrailsProvider && clientSecret && validationErrors.length === 0 && (
                    <CheckoutFormPayrails hostedSession={hostedSession}></CheckoutFormPayrails>
                )}

                <SmallFooter provider={provider}></SmallFooter>
            </Grid>
        </div>
    );
}

const footerLinks = {
    Stripe: {
        terms: "https://stripe.com/legal/end-users",
        privacy: "https://stripe.com/privacy"
    },
    Payrails:{
        terms: "https://www.payrails.com/imprint",
        privacy: "https://www.payrails.com/privacy-policy"
    }
}

function CheckoutFooter({provider}){
    if (!provider) {
        return null
    }

    return (
        <div className="lg-footer">
            <a className="dottedLink" href={footerLinks[provider].terms}>{t("terms")}</a>
            <a className="dottedLink" href={footerLinks[provider].privacy}>{t("privacy")}</a>
        </div>
    )
}

function SmallFooter({provider}){
    if (!provider) {
        return null
    }

    return (
        <div className="xs-footer" style={{textAlign: "center", bottom: 0, marginTop: "20px"}}>
            <a className="dottedLink" href={footerLinks[provider].terms}>{t("terms")}</a>
            <a className="dottedLink" href={footerLinks[provider].privacy}>{t("privacy")}</a>
        </div>
    )
}
