import config from "src/config";
import pino from 'pino'

const LOGGING_ENDPOINT = config.apis.tokenMediatorUrl + "/logs";

const pinoConfig = {
    level: 'info', // may be configured
    browser: {
        write: () => {},
        asObject: true,
        transmit: {
            level: 'info',
            send: (level, logEvent) => {
                try {
                    const logEntry = {
                        level: level,
                        message: logEvent.messages[0],
                        timestamp: logEvent.ts,
                        data: Object.assign({}, ...logEvent.bindings)
                    };

                    if (navigator.sendBeacon){
                        navigator.sendBeacon(
                            LOGGING_ENDPOINT,
                            JSON.stringify(logEntry)
                        );
                        return;
                    }

                    fallbackToXHR(JSON.stringify(logEntry));
                } catch (error) {}
            }
        }
    },
    timestamp: pino.stdTimeFunctions.isoTime,
}

const fallbackToXHR = (payload) => {
    try {
        const xhr = new XMLHttpRequest();
        xhr.open('POST', LOGGING_ENDPOINT, true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.send(payload);
    } catch (error) {}
};

const GetSessionIDFromSessionStorage = () => {
    if (!sessionStorage.getItem("sessionID")){
        sessionStorage.setItem("sessionID", crypto.randomUUID());
    }

    return sessionStorage.getItem("sessionID") || "";
}

const loggerPino = pino(pinoConfig)

export default loggerPino.child({
        sessionID: GetSessionIDFromSessionStorage(),
    }
)