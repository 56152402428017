import React, { useEffect, useMemo } from "react";
import { useLocation, useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { HostedSession } from "src/domain/HostedSession";
import "src/App.css";
import { useTranslation } from 'react-i18next';
import '@payrails/web-sdk/payrails-styles.css';
import "src/features/payment_pages/styles/Payrails.css";
import Payrails, {InitOptions, PayrailsEnvironment} from "@payrails/web-sdk";
import config from "src/config";
import { dispatch } from "src/workers/common-worker";
import { InitRequest, InitRequestType, InitResponse } from "src/workers/types";
import pseudoLocalization from 'pseudo-localization';
import logger from "src/logger";
import ct, {CountryCode} from "countries-and-timezones";

export default function CheckoutFormPayrails({hostedSession} : {hostedSession : HostedSession}) {
    const { t } = useTranslation();
    const { param } = useParams();
    
    const location = useLocation();
    const worker: Worker = useMemo(
        () => new Worker(new URL("../workers/init-worker.ts", import.meta.url)),
        []
    );
    const paymentsServiceUrl: string =  config.apis.paymentServiceUrl
    const tokenMediatorUrl: string =  config.apis.tokenMediatorUrl
    const cId = location.hash.substring(1);
    const cIdExists = cId.length > 0;

    const StagingEnv: string = "staging";
    const FailedAuthorizationBannerAutoHideTimeout = 3000; //ms

    const translate = (key: string) :string => {
        if (pseudoLocalization.isEnabled()) {
            return pseudoLocalization.localize(t(key))
        }
        return t(key)
    }

    let _logger = logger.child({
        hostedSessionID: param
    })

    useEffect(() => {
        const loadSDKConfig = () => {
            let sessionData = {
                data: hostedSession.client_secret,
            }

            let options = {
                environment: (config.paywallEnv === StagingEnv || hostedSession.test_mode) ? PayrailsEnvironment.TEST : PayrailsEnvironment.PRODUCTION,
                events: {
                    onSessionExpired: async () => {
                        try {
                            if (worker) {
                                const hsID: string = param || "";
                                const request: InitRequest = {
                                    tokenMediatorUrl: tokenMediatorUrl,
                                    paymentsServiceUrl: paymentsServiceUrl,
                                    hsID: hsID,
                                    configId: cIdExists ? cId : "",
                                    type: InitRequestType.Payment
                                };

                                const res: any = await dispatch<InitRequest, InitResponse>(worker, request)
                                   
                                return {
                                    data: res?.client_secret
                                } as InitOptions 
                            }

                            return Promise.reject("not supported operation")
                        } catch (error) {
                            return Promise.reject(error)
                        }
                          
                    }
                },
                returnInfo: {      
                    success: 'https://assets.payrails.io/html/payrails-success.html',
                    error: 'https://assets.payrails.io/html/payrails-error.html',
                    cancel: 'https://assets.payrails.io/html/payrails-cancel.html',
                    pending: 'https://assets.payrails.io/html/payrails-pending.html',
                },   
            }

            const payrailsClient = Payrails.Payrails.init(sessionData as InitOptions, options);
            const dropin = payrailsClient.dropin({
                configuration: {
                    authFailMsg: {
                        autoHideTTL: FailedAuthorizationBannerAutoHideTimeout,
                    },
                },
                styles: {
                    container: {
                        styles: {
                            backgroundColor: "#fff",
                        },
                    },
                    googlePayButton: {
                        storeInstrumentCheckbox: {
                            display: "inline-block",
                            marginTop: "8px",
                        },
                    },
                    element: {
                        active: {
                            border: "4px solid " + hostedSession.presentation_settings.branding.button_color,
                            borderRadius: "8px",
                            transition: "border-color 0.2s ease-in-out",
                        },
                        base: {
                            borderWidth: "2px",
                            borderColor: "#eae8ee",
                            borderRadius: "8px",
                            fontSize: "14px",
                            fontFamily:"Roboto, Ideal Sans, system-ui, sans-serif",
                        },
                    },
                    cardForm: {
                        wrapper: {
                            height: "min-content",
                        },
                        base: {
                            fontSize: "14px",
                            outline: "none",
                            boxSizing: "border-box",
                            display: "block",
                            height: "min-content",
                            fontFamily: "Roboto, Ideal Sans, system-ui, sans-serif"
                        },
                        inputFields: {
                            all: {
                                base: {
                                    borderTop: "1px solid #D3D3D3",
                                    borderLeft: "1px solid #D3D3D3",
                                    borderRight: "1px solid #D3D3D3",
                                    borderBottom: "1px solid #D3D3D3",
                                    borderRadius: "8px",
                                    padding: "0.8rem",
                                    boxSizing: "border-box",
                                    fontSize: "14px",
                                    fontFamily: "Roboto, Ideal Sans, system-ui, sans-serif",
                                },
                            },
                            CARDHOLDER_NAME: {
                                base: {
                                    borderTopLeftRadius: "8px",
                                    borderTopRightRadius: "8px",
                                },
                            },
                            CVV: {
                                base: {
                                    borderRadius: "8px",
                                    borderBottomRightRadius: "8px",
                                    marginLeft: "0.9rem",
                                    width: "calc(100% - 0.9rem)",
                                },
                            },
                            EXPIRATION_DATE: {
                                base: {
                                    borderRadius: "8px",
                                    borderBottomRightRadius: "8px",
                                    borderBottomLeftRadius: "8px",
                                    width: "calc(100% - 0.3rem)",
                                },
                            },
                        },
                        labels: {
                            all: {
                                fontKerning: "normal",
                                fontFamily: "Roboto, Ideal Sans, system-ui, sans-serif",
                                border: "0",
                                fontStyle: "normal",
                                margin: "0",
                                padding: "0",
                                verticalAlign: "baseline",
                                fontSize: "14px",
                                fontWeight: "400",
                                display: "block",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                transition: "color .1s ease-out",
                                whiteSpace: "nowrap",
                                boxSizing: "border-box",
                                color: "#3e3e3e",
                                marginTop: "8px",
                                borderRadius: "0px",
                            },
                            CVV: {
                                marginLeft: "0.9rem",
                            },
                        },
                    },
                    addressSelector: {
                        wrapper: {
                            display: "flex",
                            flexDirection: "row",
                            position: "relative",
                            flexWrap: "nowrap",
                            width: "100%",
                        },
                        countrySelector: {
                            wrapper: {
                                flexDirection: "column",
                                height: "100%",
                                width: "100%",
                                position: "relative",
                                display: "flex",
                                alignItems: "stretch",
                                justifyContent: "stretch",
                                overflow: "hidden",
                            },
                            element: {
                                border: "1px solid #D3D3D3",
                                borderRadius: "8px",
                                padding: "0.8rem",
                                boxShadow: "none",
                                height: "fit-content",
                                margin: "0px",
                                width: "100%",
                                color: "#1d1d1d",
                            },
                            label:{
                                color: "#3e3e3e",
                                fontKerning: "normal",
                                fontFamily: "Roboto, Ideal Sans, system-ui, sans-serif",
                                fontStyle: "normal",
                                fontSize: "14px",
                                fontWeight: "400",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                display: "block",
                            }
                        },
                        postalCodeInput: {
                            wrapper: {
                                flexDirection: "column",
                                height: "100%",
                                width: "100%",
                                position: "relative",
                                display: "flex",
                                alignItems: "stretch",
                                justifyContent: "stretch",
                                overflow: "hidden",
                            },
                            element: {
                                padding: "0.8rem",
                                borderRadius: "8px",
                                boxSizing: "border-box",
                                border: "1px solid #D3D3D3",
                                width: "calc(100% - 0.7rem)",
                                color: "#1d1d1d",
                                marginLeft: "0.7rem",
                            },
                            label:{
                                color: "#3e3e3e",
                                marginLeft: "0.7rem",
                                fontKerning: "normal",
                                fontFamily: "Roboto, Ideal Sans, system-ui, sans-serif",
                                fontStyle: "normal",
                                fontSize: "14px",
                                fontWeight: "400",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                display: "block",
                            }
                        },
                    },
                    cardPaymentButton: {
                        base: {
                            backgroundColor: hostedSession.presentation_settings.branding.button_color,
                            color: "#FFFFFF",
                            maxWidth: "100%",
                            margin: "10px auto",
                        },
                        disabled: {
                            backgroundColor: hostedSession.presentation_settings.branding.button_color,
                            opacity: "0.7",
                            color: "#FFFFFF",
                        },
                        loading: {
                            position: "relative",
                        },
                    },
                },
                paymentMethodsConfiguration: {
                    preselectFirstPaymentOption: true,
                    cards: {
                        showCardHolderName: true,
                        showStoredInstruments: true,
                        showStoreInstrumentCheckbox: false,
                        alwaysStoreInstrument: true,
                        showPaymentMethodLogo: true,
                        showSingleExpiryDateField: true,
                    },
                    payPal: {
                        showPaymentMethodLogo: true,
                        alwaysStoreInstrument: true,
                        showStoredInstruments: false,
                    },
                    googlePay: {
                        alwaysStoreInstrument: true,
                        showPaymentMethodLogo: true,
                        merchantInfo: {
                            merchantId: hostedSession.presentation_settings.google_pay.merchant_id,
                            merchantName: hostedSession.presentation_settings.google_pay.merchant_name,
                        }
                    },
                    applePay: {
                        alwaysStoreInstrument: true,
                        showPaymentMethodLogo: true,
                    }
                },
                translations: {
                    cardPaymentButton:{
                        label: translate("pay")
                    },
                    cardForm: {
                        labels: {
                            label: translate("cardLabel")!,
                            CARD_NUMBER: translate("cardNumberLabel")!,
                            CARDHOLDER_NAME: translate("cardNameLabel")!,
                            //CVV: translate("cardCVCLabel")!,
                            CVV: "CVC",
                            EXPIRATION_DATE: translate("cardExpDateLabel")!,
                        },
                        placeholders: {
                            CARD_NUMBER: "1234 1234 1234 1234",
                            CARDHOLDER_NAME: translate("cardNamePlaceholder")!,
                            CVV: translate("cardCVCPlaceholder")!,
                            EXPIRATION_DATE: translate("cardExpDatePlaceholder")!,
                        }
                    },
                    addressSelector: {
                        labels: {
                            countrySelector: translate("cardCountryLabel")!,
                            postalCodeInput: translate("cardZipCodeLabel")!
                        },
                        placeholders: {
                            postalCodeInput: "12345",
                            countrySelector: translate("cardCountryPlaceholder")!
                        }
                    },
                    paymentResult: {
                        success: translate("paymentSucceededMessage")!,
                        fail: translate("paymentFailedMessage")!,
                        pending: translate("paymentPendingMessage")!
                    }
                },
                events: {
                    // @ts-ignore
                    onAuthorizeSuccess: () => {
                        _logger.info("payment authorization successful")
                        window.location.replace(hostedSession.success_url);
                    },
                    onAuthorizeFailed: () => {
                        _logger.warn("payment authorization failed")
                    },
                    onPaymentSessionExpired: () => {
                        _logger.warn("payment session expired")
                    },
                    onThreeDSecureChallenge: () => {
                        _logger.info("3D Secure challenge initiated")
                    },
                },
            });

            dropin.mount("#dropin-container");
            _logger.info("payrails component initialized");

            selectBillingCountry().then((country) => {
                _logger.info(`${country} was selected as default billing address country`)
            }).catch(error => {
                _logger.child({
                    error: error instanceof Error ? error.message : JSON.stringify(error)
                }).warn("failed to select default billing address country")
            });
        }

        try {
            loadSDKConfig();
        } catch (error) {
            _logger.error({
                error: error instanceof Error ? error.message : JSON.stringify(error)
            }, "an error occurred during the payrails dropin initialization");
        }

        loadFavicon(hostedSession);
        setBackgroundColor(hostedSession);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (hostedSession === null || hostedSession === undefined) {
        return <p>Loading..</p>;
    }

    const loadFavicon = (hostedSession : HostedSession) => {
        const link = document.createElement('link');
        link.id = 'favicon';
        link.rel = 'shortcut icon';
        document.head.appendChild(link);

        if (hostedSession.presentation_settings.assets.favicon.indexOf("://") > 0) {
            (link as HTMLLinkElement).href = hostedSession.presentation_settings.assets.favicon;
        } else {
            (link as HTMLLinkElement).href = `/${hostedSession.presentation_settings.assets.favicon}-32x32.png`;
        }
    }

    const setBackgroundColor = (hostedSession: HostedSession) => {
        document.body.style.backgroundColor = hostedSession.presentation_settings.branding.background_color;
    }

    const selectBillingCountry = () => {
        const country = ct.getCountryForTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
        const observerTarget = ".payrails-country-select-element option";
        const trySetCountry = (country: CountryCode) => {
            const countrySelect =  document.querySelector(".payrails-country-select-element") as HTMLSelectElement;
            if (countrySelect) {
                countrySelect.value = country
                countrySelect.dispatchEvent(new Event('change', { bubbles: true }))
            }
        };

        return new Promise((resolve, reject) => {
            let timer: NodeJS.Timeout | undefined = undefined;
            if (document.querySelector(observerTarget))  {
                trySetCountry(country!.id)
                return resolve(country!.id);
            }
            const observer = new MutationObserver(()=>{
                if (document.querySelector(observerTarget)){
                    observer.disconnect();
                    trySetCountry(country!.id)

                    if (timer) {
                        clearTimeout(timer);
                    }

                    return resolve(country!.id);
                }
            });

            observer.observe(document.body, {
                childList: true,
                subtree: true
            });

            timer = setTimeout(()=>{
                observer.disconnect();
                reject();
            }, 3000);
        });
    }

    return (
        <Grid item xs={12} sm={6} md={4}>
            <div>
                <div id="dropin-container"/>
            </div>
            <div className="allow-text" style={{marginTop: "20px"}}>
                {/* to be shown only when it makes sense for the card to be used in off session */}
                {translate("checkoutCardAdvice")}
            </div>
        </Grid>
    );
}